.table_wrap {
  border: 1px solid #dee1e4;
  border-radius: 8px;
  overflow-y: scroll;
  /* 2023.02.06 khm add : 표 배경색이 투명해서 추가함 */
  background-color: white;
}

.table_style {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  overflow: auto;

  thead{
    position: sticky;
    top: 0;
    background: #01c2d1;
    color: white;
    white-space: nowrap;
  }
}
    
.table_style th {
  padding: 11px 10px;
  text-align: center;
  white-space: nowrap

}
.table_style th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.table_style th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.table_style tbody td {
  padding: 8px 10px;
  text-align: center;
  min-width: 100px;
  max-width: 200px;
  overflow: hidden;
  color: #808285;
  white-space: nowrap;
}
.tr_duplication{
  background-color: yellow
}

.tr_warning{
  background-color: orange
}

.distribution_btn_wrap {
}
.select_title {
  margin-right: 10px;
}
.office_title {
  font-size: 12px;
  color: #a8a8a8;
}
.table_wrap .title {
  font-size: 20px;
  
  margin-bottom: 10px;
}
