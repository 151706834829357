
#login_wrapper{
  position: relative;
  overflow: scroll;
  width:100%;
  height:100vh;
  margin:0 auto;
  /* background-image: url('assets/images/login_background.png'); */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffd6d9+0,ffe0dd+50,ffd2d5+100 */
  background: #ffd6d9; /* Old browsers */
  background: -moz-linear-gradient(top,  #ffd6d9 0%, #ffe0dd 50%, #ffd2d5 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #ffd6d9 0%,#ffe0dd 50%,#ffd2d5 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #ffd6d9 0%,#ffe0dd 50%,#ffd2d5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd6d9', endColorstr='#ffd2d5',GradientType=0 ); /* IE6-9 */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 아이디/비밀번호 찾기 */
#find_info #mb_hp_label {display:inline-block;margin-left:10px}
#find_info p {line-height:1.5em}
#find_info #mb_email {margin:10px 0}

/* 로그인 */
#mb_login {
    width:90%;
    height: 90vh;
    max-width: 500px;
    position: absolute;
    box-sizing: border-box;
}
#login_fs {
box-sizing: border-box;
}
#mb_login #login_fs .frm_input {margin:0 0 10px}
#mb_login #login_fs .btn_submit {
    margin:0 0 15px; 
    cursor: pointer;
    width: 100%;
    margin: 0 0 6px;
    height: auto;
    border: none;
    padding: 15px 0;
    font-size: 15px;
    line-height: 1;
    border-radius: 10px;
    color: #fff;
}

input[id="login_id"]{
border-radius: 30px;
border:1.5px solid #28cac5;
}
input[id="login_pw"]{
border-radius: 30px;
border:1.5px solid #28cac5;
}
input[id="login_id"], input[id="login_pw"]{
    width:100%;
    padding:15px;
    box-sizing: border-box;
}
.mbskin_box{
    padding:10px;
}
.mbskin{
    padding: 20px 40px;
}      
.bc_new{
    background:#28cac5;
}
.cc_new{
    color:#7ce2e3;
}
input[id="login_id"]::placeholder {
    color: #28cac5;
    font-weight:200;
}

input[id="login_pw"]::placeholder {
    color: #28cac5;
    font-weight:200;
}
#mb_login #login_fs .btn_submit{
    border-radius:30px;
}

.login_if_auto{
    text-align:left;
    display:flex;
    align-items:center;
    gap:5px;
    margin-bottom:10px;
    font-weight: 800;
    color:#28cac5;
    margin-left: 10px;
}

.login_if_lpl{
    width:100%;
    margin:0 auto;
    text-align:center;
    font-weight:800;
}
#login_info a {
    font-size: 13px;
color: #28cac5;
text-decoration: none;
}
#login_info a::after {
content: "|";
padding:0px 8px;
}
#login_info a:last-child::after{
    content:"";
}
#login_password_lost_1 {
border-bottom: none;
}
.wrap_box{
    width:100%; 
}
.wrap_box img{
  display: block;
  width:100%;
}

/* 체크박스를 위한 */
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .mbskin input[type=checkbox],
    .mbskin input[type=radio] {
        --active: #28cac5;
        --active-inner: #fff;
        --focus: 2px rgba(39, 94, 254, .3);
        --border-hover: #28cac5;
        --background: #fff;
        --disabled: #F6F8FF;
        --disabled-inner: #E1E6F9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      }
    .mbskin input[type=checkbox]:after,
    .mbskin input[type=radio]:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
      }
    .mbskin input[type=checkbox]:checked,
    .mbskin input[type=radio]:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
    .mbskin input[type=checkbox]:disabled,
    .mbskin input[type=radio]:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
      }
    .mbskin input[type=checkbox]:disabled:checked,
    .mbskin input[type=radio]:disabled:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
    .mbskin input[type=checkbox]:disabled + label,
    .mbskin input[type=radio]:disabled + label {
        cursor: not-allowed;
      }
    .mbskin input[type=checkbox]:hover:not(:checked):not(:disabled),
    .mbskin input[type=radio]:hover:not(:checked):not(:disabled) {
        --bc: var(--border-hover);
      }
    .mbskin input[type=checkbox]:focus,
    .mbskin input[type=radio]:focus {
        box-shadow: 0 0 0 var(--focus);
      }
    .mbskin input[type=checkbox]:not(.switch),
    .mbskin input[type=radio]:not(.switch) {
        width: 21px;
      }
    .mbskin input[type=checkbox]:not(.switch):after,
    .mbskin input[type=radio]:not(.switch):after {
        opacity: var(--o, 0);
      }
    .mbskin input[type=checkbox]:not(.switch):checked,
    .mbskin input[type=radio]:not(.switch):checked {
        --o: 1;
      }
    .mbskin input[type=checkbox] + label,
    .mbskin input[type=radio] + label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
        color:#28cac5;
        
      }
  
    .mbskin input[type=checkbox]:not(.switch) {
      border-radius: 50%;
    }
    .mbskin input[type=checkbox]:not(.switch):after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 6px;
      top: 2px;
      transform: rotate(var(--r, 20deg));
    }
    .mbskin input[type=checkbox]:not(.switch):checked {
      --r: 43deg;
    }
    .mbskin input[type=checkbox].switch {
      width: 38px;
      border-radius: 11px;
    }
    .mbskin input[type=checkbox].switch:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }
    .mbskin input[type=checkbox].switch:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }
    .mbskin input[type=checkbox].switch:disabled:not(:checked):after {
      opacity: 0.6;
    }
  
    .mbskin input[type=radio] {
      border-radius: 50%;
    }
    .mbskin input[type=radio]:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, 0.7));
    }
    .mbskin input[type=radio]:checked {
      --s: .5;
    }
  }
  

/* 반응형 */
@media screen and (max-width:723px) {
   
  
}
@media screen and (min-width:724px) and (max-width:1024px) {
   
}
@media screen and (min-width:1025px) {
   

}
@media screen and (min-width:1800px) {
   
}
@media (min-aspect-ratio: 8/5) {

}