.l_target_viewer_main{

}

.l_table_wrap{
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow: auto;
    border: solid black 1px;
}