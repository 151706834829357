    .baby_wrap{
        width:100%;
    }
    .baby_wrap .baby_state {
        width: 80%;
        max-width: 1200px;
        margin:0 auto;
        margin-top:30px;
        position: relative;
        box-shadow: 0px 3px 10px #63636333;
        border-radius: 10%;
    }
    
    .baby_wrap .baby_state:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    .baby_wrap .baby_state .inner {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 8%;
        box-sizing: border-box;
    }
    .baby_wrap .jusu_info{
        top:0;
        left:0;
    }
    .baby_wrap .jusu_info .baby_name{
        font-family: 'Pretendard-Medium';
        font-size: 17px;
        font-weight: bolder;
    }
    .baby_wrap .jusu_info .d_day{
        font-family: 'Pretendard-BoldExtra';
        font-size: 20px;
        font-weight: bolder;
    }
    .baby_wrap .download_btn{
        position: absolute;
        bottom:5%;
        right:5%;
        cursor: pointer;
    }