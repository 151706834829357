body{
    background-color: rgb(235, 235, 235);
}
main{
    width:100%;
    padding-top:20px;
}
.push_wrap{
    height: 100%;
    width:90%;
    max-width: 800px;
    margin:0 auto;
    background-color: white;
    position: relative;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.275); 
    padding:25px;
    box-sizing: border-box;

    overflow: auto;
}   

.push_wrap .title {
    font-size: 20px;
    
    margin-bottom:10px;
    text-align: left;
}
.div_title{
    margin:30px 0;
    text-align: left;
}
.div_wrap{
    margin-left:14px;
    margin-bottom:20px;
    text-align: left;
    border-left:1px solid #dcdcdc;
    padding:10px 0px 50px 30px;
}
.div_wrap label{
    display:block;
    font-size: 15px;
    color:#0a0a0a;
    font-weight: 800;
}
.pushFilter_wrap .filters label{
    display: inline-block;
}
@media screen and (max-width:1000px){
    .pushFilter_wrap .filters label{
        width:100%;
    } 
}
.pushFilter_wrap .filters label input[type="text"]{
    max-width: 120px;
    min-width: 120px;
}
.pushFilter_wrap .filters label select{
    max-width: 131px;
    min-width: 131px;
}
.filter_on_off {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    gap:10px;
}
.filter_on_off input[type="radio"]{
    transform: translateY(2px);
}
.pushFilter_wrap .title{
    display: block;
    min-width: 80px;
    font-size: 12px;
    margin-right:10px;
}
textarea.normal_textarea{
    resize: none;
    width:70%;
    max-width: 500px;
    border-radius: 4px;
    border:1px solid #dcdcdc;
    min-height: 150px;
    padding:10px 5px;
    box-sizing: border-box;
}
.targetList_btn{
    background-color: transparent;
    border:1px solid #dcdcdc;
    padding:4px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 4px;
}
.targetList_btn::after{
    content: "✕";
    background-color: #0a0a0a;
    border-radius: 50%;
    padding: 4px 8px;
    color:white;
    width:10px;
    display: inline-block;
    margin-left:10px;
}
.div_title label{
    display: block;
    margin-bottom:10px;
}
.div_title span{
   background-color: rgb(23, 41, 122);
   color:white;
   border-radius: 50%;
   width:30px;
   height:30px;
   text-align: center;
   vertical-align: middle;
   padding-top:5px;
   display: inline-block;
   margin-right:20px;
   box-sizing: border-box;
}
input.normal_input{
    max-width: 500px;
    width:70%;
    border:none;
    border-bottom:1px solid #dcdcdc;
    padding:5px 5px;
    box-sizing: border-box;
}

textarea.normal_textarea:focus ,input.normal_input:focus { 
    outline: none !important; 
    border-color: #e4e4e4;
    box-shadow: 0 0 10px #e4e4e4;
    border-radius: 4px;
 }

.massage_wrap{
    margin-bottom:10px;
}
.massage_wrap label{
    margin-bottom: 3px;
}
button.send_btn{
    display: block;
    width:90%;
    margin:0 auto;
    max-width:500px;
    padding:10px 0 11px 0;
    border:none;
    background-color: rgb(23, 41, 122);
    color:white;
    border-radius: 4px;
    transition: all 0.5s;
    
}
button.send_btn.no_active{
    opacity: 0.6;
}
button.send_btn.active{
    background-color: rgb(23, 41, 122);
    cursor: pointer;
}
button.send_btn.active:hover{
    background-color: rgb(4, 38, 190);
}