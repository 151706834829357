.male_on_off{
  width:90%;
  margin:0 auto;
  display: flex;
  align-items: center;
}
.male_on_off .modal{

}
.male_on_off button{

}
.male_on_off .check_box{
	transform: scale(1);
}
input::placeholder{
    color:#d4d4d4;
}
.normal_btn{
    background-color: rgb(23, 41, 122);
    color:white;
    border:none;
    border-radius: 4px;
}
.red_btn{
    background-color: red;
    color:white;
    border:none;
    border-radius: 4px;
}
.margin_top_10{
    margin-top:10px;
}
.margin_bottom_10{
    margin-bottom:10px;
}
.margin_bottom_20{
    margin-bottom:20px;
}
.margin_right_10{
    margin-right:10px;
}
.margin_left_10{
    margin-left:10px;
}
.logout_btn{
    background-color: red;
    color:white;
    border:none;
    border-radius: 4px;
}
.male_on_off input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 50px;
    height: 25px;
    background: #ccc;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
}

.male_on_off input:checked[type="checkbox"] {
    background: #7da6ff;
}

.male_on_off input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.4s;
}

.male_on_off input:checked[type="checkbox"]::after {
    left: 50%;
}