.push_wrapper{
  width:100%;
  max-width: 1500px;
}
.push_wrapper h1{
  font-size: 20px;
  padding:10px 0;
  
  font-weight: 800;
}
.push_table_wrap {
  width:100%;
  max-height: 80vh;
  border-radius: 8px;
  overflow-y: scroll;
  /* 2023.02.06 khm add : 표 배경색이 투명해서 추가함 */
  background-color: white;
}
.push_table_style_wrap {
  padding: 22px;
}
.push_table_style {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.push_table_style th {
  position: sticky;
  top: 0;
  padding: 11px 10px;
  white-space: nowrap;
  color:#c0c0cc;
  text-align: center;
  font-size: 12px;
  border-bottom:1px solid #dcdcdc;
}
.push_table_style th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.push_table_style th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.push_table_style tbody td {
  text-align: center;
  overflow: hidden;
  color: #808285;
  font-size: 16px;
  vertical-align: middle;
  padding:15px 10px;
  box-sizing: border-box;
}
.push_table_style tbody td a{
  display: block;
  box-sizing: border-box;
  overflow-wrap: break-word;
}
.push_table_style tbody tr{
  border-bottom: 1px solid #dcdcdc;
}
.push_table_style tbody tr:last-child{
  border:none;
}
.push_table_style tbody td:nth-child(1){
  min-width:300px;
  max-width:400px;
  width:200px;
  text-align: left;
  padding-left: 30px;
}
.push_table_style tbody td:nth-child(1) div:nth-child(1){
  color:#4e71c3;
  white-space: nowrap;
  border-bottom: 1px dashed #dcdcdc;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.push_table_style tbody td:nth-child(1) div:nth-child(2){
  padding-top: 10px;
  text-align: left;
  line-height: 20px;
}
.push_table_style tbody td:nth-child(2){
  min-width:200px;
  width:200px;
  text-align: center;
}
.push_table_style tbody td:nth-child(3){
  min-width:100px;
  max-width: 100px;
  width:100px;
  text-align: center;
  color:black;
  white-space: nowrap;
}
.push_table_style .delivered{
  font-size: 14px;
  color:#a1a1a1;
}
.push_table_style tbody td:nth-child(4){
  min-width:150px;
  max-width: 150px;
  width:150px;
  text-align: center;
}
.push_table_style tbody td:nth-child(5){
  min-width:150px;
  max-width: 150px;
  width:150px;
  margin:0 auto;
}
.push_table_style tbody td:nth-child(6){
  min-width:200px;
  max-width: 200px;
  width:200px;
}
.push_table_style tbody td:nth-child(7){
  min-width:200px;
  max-width: 200px;
  width:200px;
}
.push_table_style tbody td:nth-child(8){
  min-width:100px;
  max-width: 100px;
  width:100px;
}
.push_table_style tbody td:nth-child(9){
  min-width:100px;
  max-width: 100px;
  width:100px;
}
.push_table_style tbody td:nth-child(10){
  min-width:100px;
  max-width: 100px;
  width:100px;
}
.push_table_style tbody td:nth-child(11){
  min-width:130px;
  max-width: 130px;
  width:130px;
}
.push_table_style tbody td:nth-child(12){
  min-width:100px;
  max-width: 100px;
  width:100px;
}
.push_table_style tbody td img{
  display: block;
  text-align: center;
  margin:0 auto;
  width:200px;
}
.push_table_style tbody td button{
  white-space: nowrap;
  border:none;
  background-color: #e95419;
  color:white;
  padding:4px;
  border-radius: 4px;
  box-sizing: border-box;
}

/*table-pagination*/
.push_wrapper .table-pagination{
  width: 100%;
  padding:20px 0;
}
.push_wrapper .current_num{
}
.push_wrapper .table-pagination input{
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  color:gray;
  width:50px;
  padding-left:10px;
}
.push_wrapper .table-pagination button{
  background:white;
  border:none;
  margin:0 4px;
  border-radius: 4px;
  font-size: 13px;
}
.push_wrapper select{
  border: 1px solid #dcdcdc;
  padding:4px;
  border-radius: 4px;
  color:#333;
}
/* 페이지네이션 */
.table-pagesize{
  margin: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.table-pagesize input{
  border:1px solid white;
  max-width: 50px;
  margin-right:10px;
  border-radius: 4px;
}
.table-pagesize button{
  background-color: rgb(245, 65, 0);
  border:none;
  color:white;
  border-radius: 4px;
  margin-right:10px;
  padding:4px 10px;
  cursor: pointer;
}
.go_link_btn a{
  border:none;
  color:rgb(47, 85, 255);
  border-radius: 4px;
  padding:4px 10px;
  cursor: pointer;
  text-align: center;
}